<template>
<!-- 版本管理 -->
  <div class="whiteBg">
    <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
    <div class="flexAC" style="margin: 16px 0px;">
      <el-input v-model="vnum"  placeholder="请输入版本号/更新内容" class="inputSearch"></el-input>
      <el-button type="primary" class="btnSearch"  @click="handleSearch">搜索</el-button>
      <el-button class="btnReset"  @click="handleReset">重置</el-button>
    </div>
    <div class="table_list">
      <el-table
        :data="versionList"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="版本号"
          prop="vnum">
        </el-table-column>
        <el-table-column
          label="版本类型"
          prop="vtype">
        </el-table-column>
        <el-table-column
          label="更新人员"
          prop="sname">
        </el-table-column>
        <el-table-column
          label="更新内容"
          prop="contents"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="更新时间"
          prop="versiontime">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" style="color:#F44C8E" size="small" @click="handDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="pageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>
    <!-- 新增 -->
    <el-dialog title="新增" :visible.sync="showform" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="msgfrom" :rules="formRules" ref="notice">
        <el-form-item label="版本号" prop="vnum">
          <el-input v-model="msgfrom.vnum"  placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="vtype">
          <el-radio-group v-model="msgfrom.vtype" style="width: 100%;">
            <el-radio :label="2">小程序</el-radio>
            <el-radio :label="1">pc端</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="更新内容" prop="contents" class="fiText">
          <el-input class="formTextarea" v-model="msgfrom.contents" type="textarea" maxlength="150" placeholder="请输入更新内容"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { versionGet, versionAdd, versionDelete } from '@/api/system.js'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      vnum: '',
      versionList: [],
      showform: false,
      msgfrom: {
        vnum: '',
        vtype: 2,
        contents: ''
      },
      formRules: {
        vnum: [{ required: true, trigger: 'blur', message: '版本号不能为空' }],
        vtype: [{ required: true, trigger: 'blur', message: '请选择类型！' }],
        contents: [{ required: true, trigger: 'blur', message: '更新内容不能为空！' }],
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        vnum: this.vnum,
        page_index: this.page,
        page_size: this.size
      }
      versionGet(params).then(res => {
        if (res.status === 1) {
          this.versionList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.versionList = []
          this.total = 0
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 新增
    handleAdd () {
      this.showform = true
    },
    FromConfirm () {
      this.$refs.notice.validate((valid) => {
        if (!valid) return
        const params = {
          vnum: this.msgfrom.vnum,
          vtype: this.msgfrom.vtype,
          contents: this.msgfrom.contents
        }
        versionAdd(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.FromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    FromCancel () {
      this.showform = false
      this.$refs.notice.resetFields()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.vnum = ''
      this.init()
    },
    // 删除
    handDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        versionDelete({ vid: row.vid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style>

</style>
